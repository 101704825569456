import { changeInterfaceByProduct } from "./productInterfaceStylizer";

var app = angular.module("analytics");

app.controller("GenericModuleCtrl", [
  "$scope",
  "$mdPanel",
  "$mdToast",
  "exportUiGridService",
  "uiGridGroupingConstants",
  "uiGridConstants",
  "$timeout",
  "clientsFactory",
  "permissionsFactory",
  "organizationFactory",
  "$mdDateLocale",
  "$mdDialog",
  "genericFactory",
  "metaModuleFactory",
  "$stateParams",
  "$http",
  "i18nService",
  "storageFactory",
  "uiGridExporterService",
  "localizationFactory",
  "$state",
  function (
    $scope,
    $mdPanel,
    $mdToast,
    exportUiGridService,
    uiGridGroupingConstants,
    uiGridConstants,
    $timeout,
    clientsFactory,
    permissionsFactory,
    organizationFactory,
    $mdDateLocale,
    $mdDialog,
    genericFactory,
    metaModuleFactory,
    $stateParams,
    $http,
    i18nService,
    storageFactory,
    uiGridExporterService,
    localizationFactory,
    $state
  ) {
    var self = this;
    var mode = "create";
    $scope.module = {};
    $scope.activeTab = {};
    $scope.topFilters = {};
    $scope.topVerifications = {};
    $scope.deps = [];
    $scope.depsData = [];
    $scope.fieldFilters = {};
    $scope.dynamicFields = [];
    $scope.genericFactory = genericFactory;
    $scope.$mdDialog = $mdDialog;
    $scope.onOpen = true;
    $scope.currentUser = clientsFactory.getUser().user;
    $scope.topFilterData = [];
    $scope.$mdPanel = $mdPanel;
    $scope.uiGridConstants = uiGridConstants;
    $scope.uiGridGroupingConstants = uiGridGroupingConstants;
    $scope.exitingModule = false;
    $scope.filteredDataReadyForFilter = [];
    $scope.metaModuleFactory = metaModuleFactory;
    $scope.storageFactory = storageFactory;
    $scope.clientsFactory = clientsFactory;
    $scope.paginationStartIndex = 0;
    $scope.disabledCustomButtonFunctions = [];

    // Translate text

    $scope.translateText = function (text) {
      return localizationFactory.translateText(text);
    };

    $scope.localeSensitiveComparator = function (v1, v2, row1, row2, direction, column) {
      let value1 = v1;
      let value2 = v2;
      if (column != null && column.cellTemplate != null && column.cellTemplate.indexOf("parseValue") != -1) {
        value1 = $scope.parseValue(v1, column.colDef.field);
        value2 = $scope.parseValue(v2, column.colDef.field);
      }
      // If we get numbers
      if (typeof value1 == "number" && typeof value2 == "number") {
        if (value1 < value2) {
          return -1;
        } else if (value1 > value2) {
          return 1;
        } else {
          return 0;
        }
      }

      // If we get strings
      if (typeof value1 == "string" && typeof value2 == "string") {
        if (
          value1.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
          value2.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        ) {
          return -1;
        }

        if (
          value1.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
          value2.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        ) {
          return 1;
        }
        return 0;
      }
    };

    // Workaround to use exportReport in the genericModule-Utils file
    //$scope.exportReport = exportReport;

    if ($scope.currentUser && $scope.currentUser.hasToResetPassword) {
      $state.go("client");
    }

    $scope.getOrgType = function (orgId) {
      var filteredOrg = organizationFactory.data.filter((e) => e.id === orgId);

      if (filteredOrg.length) {
        return filteredOrg[0].orgType;
      } else {
        return "";
      }
    };

    if ($scope.currentUser && $scope.currentUser.orgType) {
      changeInterfaceByProduct($scope.currentUser.orgType);
    }

    $scope.getOrgName = function (orgId) {
      var filteredOrg = organizationFactory.data.filter((e) => e.id === orgId);

      if (filteredOrg.length) {
        return filteredOrg[0].name;
      } else {
        return "";
      }
    };

    $scope.clearSearchTerm = function (fieldDesc, field) {
      fieldDesc.searchTerm = "";
      if (
        $scope.topFilters.hasOwnProperty(field) &&
        ($scope.topFilters[field] == "Todos" || $scope.topFilters[field] == "All")
      ) {
        delete $scope.topFilters[field];
      }
    };

    $scope.getCurrentLang = function () {
      let lang = getCookie("KSTKLang");
      if (lang == null || lang == "pt-PT") {
        return "pt";
      } else if (lang == "en-GB") {
        return "en";
      }
    };

    i18nService.setCurrentLang($scope.getCurrentLang());

    $scope.gridOptions = {
      enableRowSelection: true,
      enableFullRowSelection: true,
      multiSelect: false,
      enableHorizontalScrollbar: 0,
      enableHorizontalScrollbar: true,
      enableVerticalScrollbar: true,
      enableGridMenu: true,
      exporterMenuPdf: false,
      exporterMenuCsv: false,
      exporterMenuExcel: false,
      enableColumnResizing: true,
      exporterOlderExcelCompatibility: false,
      enableFiltering: true,
      showColumnFooter: true,
      flatEntityAccess: true,
      //fastWatch: true
    };

    $scope.exportUiGridService = exportUiGridService;

    // Creates error "Asked to addToGridMenu, but gridMenuScope not present."
    var refresh = function () {
      $scope.refresh = true;
      $timeout(function () {
        $scope.refresh = false;
      }, 0);
    };

    $mdDateLocale.formatDate = function (date) {
      if (!date || date == "Invalid Date") {
        return "";
      }
      var day = date.getDate();
      var monthIndex = date.getMonth();
      var year = date.getFullYear();

      return day + "/" + (monthIndex + 1) + "/" + year;
    };

    $mdDateLocale.parseDate = function (dateString) {
      var parts = dateString.split("/");
      var d = new Date(parts[2] + "-" + parts[1] + "-" + parts[0]);
      return d;
    };

    $scope.moduleCollection = $stateParams.moduleCollection;
    $scope.moduleValue = $stateParams.moduleValue;

    genericFactory.setRouteName($scope.moduleCollection);
    genericFactory.setParentScope($scope);
    $scope.parentScope = genericFactory.getParentScope();

    $scope.customCalculatedFieldsValues = {};
    $scope.customCalculatedFieldsIntervals = [];

    $scope.gridOptions.onRegisterApi = function (gridApi) {
      $scope.gridApi = gridApi;

      setInterval(
        () => {
          $scope.gridApi.core.handleWindowResize();
        },
        500,
        10
      );

      gridApi.core.on.renderingComplete($scope, function () {
        $scope.gridApi.grid.refreshCanvas();
        $scope.parentScope.$parent.hideLoader();
      });

      gridApi.selection.on.rowSelectionChanged($scope, function (row) {
        if (row.isSelected) {
          $scope.selectedRow = row.entity;
          if ($scope.activeTab.onlyOneConcurrentEditorControl) {
            $scope.genericFactory.get(row.entity.id).then(function (updatedSelected) {
              genericFactory.setSelected(updatedSelected);
            });
          } else {
            genericFactory.setSelected(row.entity);
          }

          if ($scope.currentUser && $scope.currentUser.isKSTK) {
            console.log(row.entity);
          }
        } else {
          $scope.selectedRow = null;
          genericFactory.setSelected(null);
        }
      });

      $scope.$watch(
        "[topFilters]",
        function (filters) {
          if ($scope.activeTab.filteredDataTab == null && $scope.customTopFiltersFlag == null) {
            if (
              !(Object.keys($scope.topFilters).length === 0 && $scope.topFilters.constructor === Object) &&
              $scope.gridApi.grid.columns.length > 0
            ) {
              var fields = Object.keys($scope.activeTab.fields);
              for (var filter in Object.values(filters)[0]) {
                /* var filterIndex = fields.indexOf(filter) + 2; */
                let filterIndex = getFilterIndex($scope, fields, filter);
                if (
                  Object.values(filters)[0][filter] === "Todos" ||
                  Object.values(filters)[0][filter] === "All" ||
                  Object.values(filters)[0][filter] === ""
                ) {
                  $scope.gridApi.grid.columns[filterIndex].filters[0].term = "";
                } else {
                  if ($scope.activeTab.fields[filter].topFilterOrder == ">=") {
                    $scope.gridApi.grid.columns[filterIndex].filters[0].condition =
                      uiGridConstants.filter.GREATER_THAN_OR_EQUAL;
                  } else if ($scope.activeTab.fields[filter].topFilterOrder == "<=") {
                    $scope.gridApi.grid.columns[filterIndex].filters[0].condition =
                      uiGridConstants.filter.LESS_THAN_OR_EQUAL;
                  }
                  $scope.gridApi.grid.columns[filterIndex].filters[0].term = Object.values(filters)[0][filter];
                }
              }
            }
          } else if ($scope.customTopFiltersFlag != null) {
            if (
              !(Object.keys($scope.topFilters).length === 0 && $scope.topFilters.constructor === Object) &&
              $scope.gridApi.grid.columns.length > 0
            ) {
              var fields = Object.keys($scope.activeTab.fields);
              for (var filter in Object.values(filters)[0]) {
                if ($scope.activeTab.fields[filter].calculatedTopFilter) {
                  let filterIndex = getFilterIndex($scope, fields, filter);
                  let columnFilterValue = Object.values(filters)[0][filter];
                  if (columnFilterValue === "Todos" || columnFilterValue === "All" || columnFilterValue === "") {
                    $scope.gridApi.grid.columns[filterIndex].filters[0].term = "";
                  } else {
                    $scope.gridApi.grid.columns[filterIndex].filters[0].term = columnFilterValue;
                  }
                }
              }
            }
          }
        },
        true
      );

      $scope.gridOptions.exporterFieldCallback = function (grid, row, col, value) {
        if (value != null && value != undefined && Object.keys($scope.activeTab.fields).indexOf(col.name) != -1) {
          if ($scope.activeTab.fields[col.name].viewType == "date") {
            value = $scope.msToDate(value);
          }
          if (
            ($scope.activeTab.fields[col.name].fromTable != null &&
              $scope.activeTab.fields[col.name].calculated == null &&
              $scope.activeTab.fields[col.name].viewType != "dynamicField") ||
            ($scope.activeTab.fields[col.name].fromTable != null &&
              $scope.activeTab.fields[col.name].calculated != null &&
              $scope.activeTab.fields[col.name].filteredBy != null)
          ) {
            value = $scope.parseValue(value, col.name);
          }
          if (
            $scope.activeTab.fields[col.name].viewType == "dynamicField" &&
            $scope.activeTab.fields[col.name].parseFunction != null &&
            $scope.activeTab.fields[col.name].parseFunction != ""
          ) {
            value = $scope.customParseFunction(
              $scope.activeTab.fields[col.name].parseFunction,
              grid.getCellValue(row, col),
              col.field
            );
          }
        }
        return $scope.parseViewValues(value, col.field);
      };
    };

    $scope.clearFilters = function () {
      $scope.parentScope.$parent.showLoader();
      $scope.topFilters = {};
      for (let i = 2; i < $scope.gridApi.grid.columns.length; i++) {
        let col = $scope.gridApi.grid.columns[i];
        if (Object.keys(col.filters[0]).length != 0 && col.filters[0].term) {
          delete col.filters[0].term;
        }
      }
      $scope.parentScope.$parent.hideLoader();
      if ($scope.activeTab.filteredDataTab || $scope.customTopFiltersFlag) {
        $scope.getModuleData();
      }
    };

    $scope.changeTab = function (tabValue, tabName) {
      $scope.parentScope.$parent.showLoader();

      $scope.dynamicFields = [];
      cleanTopFilters($scope.topFilters);
      $scope.activeTab = tabValue;
      $scope.activeTabName = tabName;
      var columns = [];
      var fields = tabValue.fields;
      let globalWidth;

      if ($scope.activeTab.globalWidth != null) {
        globalWidth = $scope.activeTab.globalWidth;
      }

      for (var field in fields) {
        var f = {};
        if (fields[field].viewType) {
          if (fields[field].viewType == "dynamicField") {
            $scope.dynamicFields.push(field);
          }
        }
        if (fields[field].filteredBy != null) {
          $scope.fieldFilters[field] = fields[field].filteredBy;
        }
        if (
          fields[field].hidden ||
          fields[field].hiddenInTable ||
          field == "text" ||
          (fields[field].hiddenByOrganization != null &&
            fields[field].hiddenByOrganization.indexOf($scope.currentUser.organization) != -1)
        ) {
          continue;
        }
        if (globalWidth != null && fields[field].width == null) {
          f["width"] = globalWidth;
        } else {
          if (fields[field].width == null) {
            f["width"] = "*";
          } else {
            f["width"] = fields[field].width;
          }
        }

        if (tabValue.percentages != null && fields[field].grouping > 0) {
          f["field"] = field + "percentage";
        } else {
          f["field"] = field;
        }
        f["displayName"] = fields[field].label;
        if (fields[field].type == "currency.euro") {
          f["type"] = "string";
          f["cellTemplate"] =
            '<div class="ui-grid-cell-contents" >{{grid.appScope.parseViewValues(grid.getCellValue(row, col), col.field)}}</div>';
        } else {
          f["type"] = fields[field].type;
        }
        if (fields[field].wordBoundColumnFilter == true) {
          f["filter"] = {
            condition: function (searchTerm, cellValue, row, column) {
              if (cellValue != null || cellValue != undefined) {
                var re = new RegExp("^" + searchTerm + "$", "gi");
                return cellValue.match(re);
              }
              return null;
            },
          };
        }
        if (fields[field].firstAndLastWordSearch == true) {
          f["filter"] = {
            condition: function (searchTerm, cellValue, row, column) {
              if (cellValue != null && searchTerm != null) {
                let searchText = JSON.parse(JSON.stringify(searchTerm));
                var re;
                if (searchText.indexOf(" ") != -1) {
                  searchText = searchText.replace(/ /gi, ".*");
                  re = new RegExp("^" + searchText + "$", "gi");
                } else {
                  re = new RegExp("^" + searchText, "gi");
                }
                return cellValue.toString().match(re);
              }
            },
          };
        }
        if (fields[field].viewType) {
          if (fields[field].viewType == "date") {
            f["cellTemplate"] =
              '<div class="ui-grid-cell-contents" >{{grid.appScope.msToDate(grid.getCellValue(row, col))}}</div>';
            f["filter"] = {
              condition: function (searchTerm, cellValue, row, column) {
                var re = new RegExp(searchTerm, "gi");
                return $scope.msToDate(cellValue).match(re);
              },
            };
          }
        }
        if (
          fields[field].list != null &&
          fields[field].viewType == "dropdown" &&
          fields[field].fromTable == null &&
          fields[field].calculated == null
        ) {
          f["cellTemplate"] = '<div class="ui-grid-cell-contents" >{{grid.getCellValue(row, col).toString()}}</div>';
        }
        if (
          ((fields[field].fromTable != null && fields[field].calculated == null) ||
            (fields[field].fromTable != null && fields[field].calculated != null && fields[field].filteredBy != null) ||
            (fields[field].fromTable != null && fields[field].calculated != null && fields[field].list != null)) &&
          fields[field].viewType != "dynamicField"
        ) {
          if (tabValue.percentages == null || tabValue.percentages.indexOf(field) == -1) {
            if (fields[field].type == "currency.euro") {
              f["cellTemplate"] =
                '<div class="ui-grid-cell-contents" >{{grid.appScope.parseValue(grid.getCellValue(row, col), col.field) | currency}}</div>';
            } else {
              f["cellTemplate"] =
                '<div class="ui-grid-cell-contents" >{{grid.appScope.parseValue(grid.getCellValue(row, col), col.field)}}</div>';
            }
            if (fields[field].wordBoundColumnFilter == true) {
              f["filter"] = {
                condition: function (searchTerm, cellValue, row, column) {
                  if (cellValue != null || cellValue != undefined) {
                    var re = new RegExp("^" + searchTerm + "$", "gi");
                    return $scope.parseValue(cellValue, column.field).match(re);
                  }
                  return null;
                },
              };
            } else {
              f["filter"] = {
                condition: function (searchTerm, cellValue, row, column) {
                  if (cellValue != null || cellValue != undefined) {
                    var re = new RegExp(searchTerm, "gi");
                    return $scope.parseValue(cellValue, column.field).match(re);
                  }
                },
              };
            }
          }
        }
        if (fields[field].parseFunction != null) {
          f["cellTemplate"] =
            '<div class="ui-grid-cell-contents" >{{grid.appScope.customParseFunction("' +
            fields[field].parseFunction +
            '", grid.getCellValue(row, col), col.field)}}</div>';
        }
        if (fields[field].filterFunction != null) {
          f["filter"] = {
            condition: function (searchTerm, cellValue, row, column) {
              return eval(fields[column.field].filterFunction);
            },
          };
        }
        if (fields[field].sort != null) {
          f.sortingAlgorithm = $scope.localeSensitiveComparator;
        }
        if (fields[field].grouping != null) {
          f["grouping"] = {
            groupPriority: fields[field].grouping,
          };
          if (fields[field].sort != null) {
            f["sort"] = {
              priority: fields[field].sort.priority,
              direction: fields[field].sort.direction,
            };
          } else {
            f["sort"] = {
              priority: fields[field].grouping,
              direction: "asc",
            };
          }
        }
        if (fields[field].grouping == null && fields[field].sort != null) {
          f["sort"] = {
            priority: fields[field].sort.priority,
            direction: fields[field].sort.direction,
          };
        }
        if (fields[field].aggregationFunction != null) {
          if (fields[field].aggregationFunction == "SUM") {
            if (fields[field].customSUMAggregationFunction == null) {
              f["treeAggregationType"] = uiGridGroupingConstants.aggregation.SUM;
              f["customTreeAggregationFinalizerFn"] = function (aggregation) {
                aggregation.rendered = Math.round(aggregation.value * 100) / 100;
                //Alert the grid to the new cell template aggregation function
                $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.EDIT);
              };
            } else if (fields[field].customSUMAggregationFunction == "iptDistinctCostAssessmentSUM") {
              f["customTreeAggregationFinalizerFn"] = function (aggregation) {
                aggregation.rendered = Math.round(aggregation.value * 100) / 100;
              };
              f["customTreeAggregationFn"] = function (aggregation, fieldValue, numValue, row) {
                if (aggregation.uniqueCostList == null) {
                  aggregation.uniqueCostList = [];
                }
                if (aggregation.uniqueCostList.indexOf(row.entity.costAssessmentCod) == -1 || row.entity.vn == "Não") {
                  if (aggregation.value == null) {
                    aggregation.value = 0;
                  }
                  if (numValue) {
                    aggregation.value += numValue;
                  }
                  aggregation.uniqueCostList.push(row.entity.costAssessmentCod);
                }
              };
            }
          } else if (fields[field].aggregationFunction == "AVG") {
            if (fields[field].customAVGAggregationFunction == null) {
              f["customTreeAggregationFinalizerFn"] = function (aggregation) {
                aggregation.rendered = Math.round(aggregation.value * 100) / 100;
              };
              f["treeAggregationType"] = uiGridGroupingConstants.aggregation.AVG;
            } else if (fields[field].customAVGAggregationFunction == "zeroOneAVG") {
              f["customTreeAggregationFinalizerFn"] = function (aggregation) {
                aggregation.rendered = Math.round(aggregation.value * 1000) / 10 + " %";
              };
              f["customTreeAggregationFn"] = function (aggregation, fieldValue, numValue, row) {
                if (numValue == 1 || numValue == 0) {
                  if (typeof aggregation.count === "undefined") {
                    aggregation.count = 0;
                  }
                  aggregation.count++;

                  if (!isNaN(numValue)) {
                    if (typeof aggregation.total === "undefined") {
                      aggregation.total = 0;
                    }
                    aggregation.total = aggregation.total + numValue;
                  }
                  aggregation.value = aggregation.total / aggregation.count;
                }
              };
            } else if (fields[field].customAVGAggregationFunction == "distinctZeroOneAVG") {
              f["customTreeAggregationFinalizerFn"] = function (aggregation) {
                aggregation.rendered = Math.round(aggregation.value * 1000) / 10 + " %";
              };
              f["customTreeAggregationFn"] = function (aggregation, fieldValue, numValue, row) {
                if (numValue == 1 || numValue == 0) {
                  if (typeof aggregation.count === "undefined") {
                    aggregation.count = 0;
                  }
                  let distinctValue = eval(
                    $scope.activeTab.fields[aggregation.col.field].rowDistinctKey.replace(
                      new RegExp("{}", "g"),
                      "row.entity"
                    )
                  );
                  if (typeof aggregation.distinctValues === "undefined") {
                    aggregation.distinctValues = [];
                  }
                  if (!isNaN(numValue)) {
                    if (typeof aggregation.total === "undefined") {
                      aggregation.total = 0;
                    }
                  }
                  if (
                    distinctValue != null &&
                    distinctValue != "" &&
                    aggregation.distinctValues.indexOf(distinctValue) == -1
                  ) {
                    aggregation.distinctValues.push(distinctValue);
                    aggregation.total = aggregation.total + numValue;
                    aggregation.count++;
                  }
                  aggregation.value = aggregation.total / aggregation.count;
                }
              };
            }
          } else if (fields[field].aggregationFunction == "COUNT") {
            if (fields[field].customCOUNTAggregationFunction == null) {
              f["treeAggregationType"] = uiGridGroupingConstants.aggregation.COUNT;
              f["customTreeAggregationFinalizerFn"] = function (aggregation) {
                aggregation.rendered = String(aggregation.value).substr(0, 16);
              };
            } else {
              if (fields[field].customCOUNTAggregationFunction == "simCOUNT") {
                f["customTreeAggregationFinalizerFn"] = function (aggregation) {
                  aggregation.rendered = Math.round(aggregation.value * 100) / 100;
                };
                f["customTreeAggregationFn"] = function (aggregation, fieldValue, numValue, row) {
                  console.log(fieldValue);
                  if (fieldValue == "Sim") {
                    if (typeof aggregation.count === "undefined") {
                      aggregation.count = 0;
                    }
                    aggregation.count++;
                    aggregation.value = aggregation.count;
                  }
                };
              } else if (
                fields[field].rowDistinctKey &&
                fields[field].customCOUNTAggregationFunction == "distinctOneCOUNT"
              ) {
                f["customTreeAggregationFinalizerFn"] = function (aggregation) {
                  aggregation.rendered = Math.round(aggregation.value * 100) / 100;
                };
                f["customTreeAggregationFn"] = function (aggregation, fieldValue, numValue, row) {
                  // calculates the average of the squares of the values
                  if (numValue == 1) {
                    if (typeof aggregation.count === "undefined") {
                      aggregation.count = 0;
                    }
                    let distinctValue = eval(
                      $scope.activeTab.fields[aggregation.col.field].rowDistinctKey.replace(
                        new RegExp("{}", "g"),
                        "row.entity"
                      )
                    );

                    if (typeof aggregation.distinctValues === "undefined") {
                      aggregation.distinctValues = [];
                    }
                    if (
                      distinctValue != null &&
                      distinctValue != "" &&
                      aggregation.distinctValues.indexOf(distinctValue) == -1
                    ) {
                      aggregation.distinctValues.push(distinctValue);
                      aggregation.count++;
                    }
                    aggregation.value = aggregation.count;
                  }
                };
              }
            }
          }
          f["cellTemplate"] =
            '<div class="ui-grid-cell-contents" >{{grid.appScope.parseViewValues(grid.getCellValue(row, col), col.field)}}</div>';
        }
        columns.push(f);
        f = {};
      }

      if ($scope.onOpen == false) {
        $scope.isChangingTab = true;
        $scope.getModuleData();
      }

      $scope.onOpen = false;

      $scope.setDefaultGridMenuCustomItems();

      if (tabValue.gridMenuCustomItems && tabValue.gridMenuCustomItems.length > 0) {
        $scope.setGridMenuCustomItems(tabValue.gridMenuCustomItems);
      }

      $scope.setGridOptionsColumns(columns);

      if ($scope.gridApi) {
        //$scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.ALL);
        //refresh();
        $scope.gridApi.grid.refreshCanvas();
      }
    };

    function getModuleMetadata() {
      permissionsFactory.getByProperty("user", $scope.currentUser.id).then(function (userPermissions) {
        if (userPermissions != null && Array.isArray(userPermissions) && userPermissions.length > 0) {
          permissionsFactory
            .getClientModulePermission({ userPermissions: userPermissions[0].id, module: $scope.moduleValue })
            .then(function (clientHasPermissionToViewModuleFlag) {
              if (!clientHasPermissionToViewModuleFlag.data.hasPermission) {
                $state.go("home");
              } else {
                if (clientHasPermissionToViewModuleFlag.data.permissions) {
                  $scope.userModulePermissions = clientHasPermissionToViewModuleFlag.data.permissions;
                }
                let map = {};
                map.collection = $scope.moduleCollection;
                map.value = $scope.moduleValue;
                metaModuleFactory
                  .getByProperties(map, null, true)
                  .then(function (data) {
                    $scope.module = data[0];
                    $scope.paginationSize = $scope.module.paginationSize;
                    $scope.$parent.setTitle($scope.translateText("modules") + " > " + data[0].name);
                    $scope.$parent.log({
                      type: "module view",
                      details: data[0].name,
                      date: new Date(),
                    });

                    var firstTab = Object.keys(data[0].tabs)[0];
                    $scope.changeTab(data[0].tabs[firstTab], firstTab);
                  })
                  .then(function (data) {
                    for (var tab in $scope.module.tabs) {
                      for (var field in $scope.module.tabs[tab].fields) {
                        if ($scope.module.tabs[tab].fields[field]["fromTable"] != null) {
                          if ($scope.deps.indexOf($scope.module.tabs[tab].fields[field]["fromTable"]) == -1) {
                            $scope.deps.push($scope.module.tabs[tab].fields[field]["fromTable"]);
                          }
                        }
                        if ($scope.module.tabs[tab].fields[field]["fromTableAux"] != null) {
                          for (let j = 0; j < $scope.module.tabs[tab].fields[field]["fromTableAux"].length; j++) {
                            let fromTableCollection = $scope.module.tabs[tab].fields[field]["fromTableAux"][j];
                            if ($scope.deps.indexOf(fromTableCollection) == -1) {
                              $scope.deps.push(fromTableCollection);
                            }
                          }
                        }
                        if ($scope.module.tabs[tab].fields[field]["auxFromTable"] != null) {
                          if ($scope.deps.indexOf($scope.module.tabs[tab].fields[field]["auxFromTable"]) == -1) {
                            $scope.deps.push($scope.module.tabs[tab].fields[field]["auxFromTable"]);
                          }
                        }
                        if ($scope.module.tabs[tab].fields[field]["viewType"] == "dynamicField") {
                          for (const key in $scope.module.tabs[tab].fields[field]["dynamicFields"]) {
                            if ($scope.module.tabs[tab].fields[field]["dynamicFields"].hasOwnProperty(key)) {
                              const element = $scope.module.tabs[tab].fields[field]["dynamicFields"][key];
                              if (element.fromTable != null) {
                                if ($scope.deps.indexOf(element["fromTable"]) == -1) {
                                  $scope.deps.push(element["fromTable"]);
                                }
                              }
                              if (element.auxFromTable != null) {
                                if ($scope.deps.indexOf(element["auxFromTable"]) == -1) {
                                  $scope.deps.push(element["auxFromTable"]);
                                }
                              }
                            }
                          }
                        }
                      }
                    }

                    if ($scope.deps.length > 0) {
                      getDependencyData($scope.deps);
                    } else {
                      $scope.getModuleData();
                    }
                  });
              }
            });
        } else {
          $state.go("home");
        }
      });
    }

    getModuleMetadata();

    function getDependencyData(deps) {
      let dependencyDataPromiseArr = getDependencyDataPromiseArr(deps);
      //$scope.depsData = genericFactory.getAllList(deps, $scope.currentUser.organization);
      Promise.all(dependencyDataPromiseArr).then(function (success) {
        for (let i = 0; i < success.length; i++) {
          $scope.depsData[i] = success[i];
        }
        $scope.getModuleData();
      });
    }

    function getDependencyDataPromiseArr(deps) {
      let promiseArr = [];

      deps.forEach((dep) => {
        genericFactory.setRouteName(dep);
        let depDataWasRequestedFlag = false;
        // Get the dep active tab field desc
        for (let field in $scope.activeTab.fields) {
          if (Object.hasOwnProperty.call($scope.activeTab.fields, field)) {
            let fieldDesc = $scope.activeTab.fields[field];
            if (fieldDesc.fromTable == dep) {
              if (fieldDesc.getAllFromTable) {
                promiseArr.push(genericFactory.getAll());
                depDataWasRequestedFlag = true;
                break;
              }
              if (fieldDesc.unfilteredFromTable) {
                promiseArr.push(genericFactory.getByProperty("organization", $scope.currentUser.organization));
                depDataWasRequestedFlag = true;
                break;
              }
              // Create database fetch based on the matching field desc filtered by fields
              // Only applies if currentUser has the filtered by fields in its information
              let depCollectionFilter = {};
              if (fieldDesc.filteredBy && Array.isArray(fieldDesc.filteredBy) && fieldDesc.filteredBy.length > 0) {
                fieldDesc.filteredBy.forEach((filteredByField) => {
                  if (
                    (!Array.isArray($scope.currentUser[filteredByField]) &&
                      $scope.currentUser[filteredByField] != null &&
                      $scope.currentUser[filteredByField] != "") ||
                    ($scope.currentUser[filteredByField] != null &&
                      Array.isArray($scope.currentUser[filteredByField]) &&
                      $scope.currentUser[filteredByField].length > 0)
                  ) {
                    depCollectionFilter[filteredByField] = $scope.currentUser[filteredByField];
                  }
                });
                if (Object.keys(depCollectionFilter).length > 0) {
                  promiseArr.push(genericFactory.getByProperties(depCollectionFilter, $scope.currentUser.organization));
                  depDataWasRequestedFlag = true;
                  break;
                } else {
                  promiseArr.push(genericFactory.getByProperty("organization", $scope.currentUser.organization));
                  depDataWasRequestedFlag = true;
                  break;
                }
              } else {
                if (dep == "Class_Plan_Students" || dep == "KSTK_Students") {
                  let depCollectionFilter = getSpecificCollectionFilterBasedOnCurrentUserFields(["year", "class"]);
                  promiseArr.push(genericFactory.getByProperties(depCollectionFilter, $scope.currentUser.organization));
                  depDataWasRequestedFlag = true;
                  break;
                } else if (dep == "Class_Plan_Essential_Learnings") {
                  let depCollectionFilter = getSpecificCollectionFilterBasedOnCurrentUserFields([
                    "schoolYear",
                    "subject",
                  ]);
                  promiseArr.push(genericFactory.getByProperties(depCollectionFilter, $scope.currentUser.organization));
                  depDataWasRequestedFlag = true;
                  break;
                } else {
                  promiseArr.push(genericFactory.getByProperty("organization", $scope.currentUser.organization));
                  depDataWasRequestedFlag = true;
                  break;
                }
              }
            }
          }
        }
        if (!depDataWasRequestedFlag) {
          if (dep == "Class_Plan_Students" || dep == "KSTK_Students") {
            let depCollectionFilter = getSpecificCollectionFilterBasedOnCurrentUserFields(["year", "class"]);
            promiseArr.push(genericFactory.getByProperties(depCollectionFilter, $scope.currentUser.organization));
          } else if (dep == "Class_Plan_Essential_Learnings") {
            let depCollectionFilter = getSpecificCollectionFilterBasedOnCurrentUserFields(["schoolYear", "subject"]);
            promiseArr.push(genericFactory.getByProperties(depCollectionFilter, $scope.currentUser.organization));
          } else {
            promiseArr.push(genericFactory.getByProperty("organization", $scope.currentUser.organization));
          }
        }
      });

      genericFactory.setRouteName($scope.moduleCollection);

      return promiseArr;
    }

    function getSpecificCollectionFilterBasedOnCurrentUserFields(fields) {
      let depCollectionFilter = {};
      fields.forEach((field) => {
        if (
          (!Array.isArray($scope.currentUser[field]) &&
            $scope.currentUser[field] != null &&
            $scope.currentUser[field] != "") ||
          ($scope.currentUser[field] != null &&
            Array.isArray($scope.currentUser[field]) &&
            $scope.currentUser[field].length > 0)
        ) {
          depCollectionFilter[field] = $scope.currentUser[field];
        }
      });
      return depCollectionFilter;
    }

    $scope.getRowCustomPermissions = function (el) {
      let flag = true;
      if ($scope.selectedRow != null && $scope.activeTab.rowCustomPermissionsFunctions != null) {
        $scope.activeTab.rowCustomPermissionsFunctions.forEach((functionName) => {
          flag = flag && eval(window[functionName]($scope, el));
        });
      }
      return flag;
    };

    $scope.getModuleData = function (mode) {
      if (!clientsFactory.getUser().user) {
        return;
      }
      var org = clientsFactory.getUser().user.organization;

      // Set up module with data filtered by top filters
      if (
        $scope.module.filteredModuleDataByTopFiltersProfiles &&
        $scope.module.filteredModuleDataByTopFiltersProfiles.indexOf($scope.currentUser.profile) != -1 &&
        $scope.topFilters &&
        mode != "filteredModuleDataByTopFilters"
      ) {
        $scope.customTopFiltersFlag = true;
        $scope.gridOptions.data = [];
        $scope.parsedRequiredTopFilters = [];
        if ($scope.activeTab.requiredTopFilters) {
          $scope.activeTab.requiredTopFilters.forEach((field) => {
            $scope.parsedRequiredTopFilters.push($scope.activeTab.fields[field].label);
          });
        }
        $scope.parentScope.$parent.alertRequiredTopFiltersWithoutData($scope.parsedRequiredTopFilters);
        $scope.parentScope.$parent.hideLoader();
        return;
      }

      $scope.parentScope.$parent.showLoader();
      if ($scope.module.getAllRows != null && $scope.module.getAllRows == true) {
        genericFactory.getAll().then(function (data) {
          $scope.runCustomFunctions(data, mode);
        });
      } else if ($scope.module.endpoint != null) {
        genericFactory.getCustomEndpoint(org, $scope.module.endpoint).then(function (data) {
          /* $scope.setGridData(data);
          $scope.parentScope.$parent.hideLoader(); */
          $scope.runCustomFunctions(data);
        });
      }
      // Get module data filtered by top filters
      else if (mode == "filteredModuleDataByTopFilters") {
        if ($scope.checkRequiredTopFilters()) {
          let topFiltersWithoutCalculatedFields = JSON.parse(JSON.stringify($scope.topFilters));
          for (let field in topFiltersWithoutCalculatedFields) {
            if ($scope.activeTab.fields[field].calculatedTopFilter) {
              delete topFiltersWithoutCalculatedFields[field];
            }
          }
          genericFactory.getByProperties(topFiltersWithoutCalculatedFields, org).then(function (data) {
            $scope.runCustomFunctions(data, mode);
          });
        } else {
          $scope.parentScope.$parent.hideLoader();
          $scope.parentScope.$parent.alertRequiredTopFiltersWithoutData($scope.parsedRequiredTopFilters);
          return;
        }
      } else if ($scope.module.customRowFilter) {
        let primaryMapCustomRowFilters = {};
        let secondaryMapCustomRowFilters = {};
        for (const rowFilter in $scope.module.customRowFilter) {
          if ($scope.module.customRowFilter.hasOwnProperty(rowFilter)) {
            const rowFilterProp = $scope.module.customRowFilter[rowFilter];
            if (
              rowFilterProp.source == "currentUser" &&
              $scope.currentUser[rowFilter] != null &&
              rowFilterProp.excludePrimaryRowFilterMap == null
            ) {
              if (rowFilterProp.targetCurrentUserField != null) {
                primaryMapCustomRowFilters[rowFilterProp.targetCurrentUserField] = {};
                primaryMapCustomRowFilters[rowFilterProp.targetCurrentUserField] = $scope.currentUser[rowFilter];
              } else {
                primaryMapCustomRowFilters[rowFilter] = {};
                primaryMapCustomRowFilters[rowFilter] = $scope.currentUser[rowFilter];
              }
            } else if (rowFilterProp.source == "custom" && rowFilterProp.value != null) {
              primaryMapCustomRowFilters[rowFilter] = {};
              primaryMapCustomRowFilters[rowFilter] = rowFilterProp.value;
            }
          }
        }

        if (
          $scope.module.customRowFilterSecondaryProfile != null &&
          $scope.currentUser.profile == $scope.module.customRowFilterSecondaryProfile
        ) {
          for (const rowFilter in $scope.module.customRowFilter) {
            if ($scope.module.customRowFilter.hasOwnProperty(rowFilter)) {
              const rowFilterProp = $scope.module.customRowFilter[rowFilter];
              if (
                rowFilterProp.source == "currentUser" &&
                $scope.currentUser[rowFilter] != null &&
                rowFilterProp.excludeSecondaryRowFilterMap == null
              ) {
                if (rowFilterProp.targetCurrentUserField != null) {
                  secondaryMapCustomRowFilters[rowFilterProp.targetCurrentUserField] = {};
                  secondaryMapCustomRowFilters[rowFilterProp.targetCurrentUserField] = $scope.currentUser[rowFilter];
                } else {
                  secondaryMapCustomRowFilters[rowFilter] = {};
                  secondaryMapCustomRowFilters[rowFilter] = $scope.currentUser[rowFilter];
                }
              }
            }
          }
        }
        /* if ($scope.activeTab.fastFilteredDataTab && $scope.activeTab.topFilters) {
          genericFactory.getFilteredData(primaryMapCustomRowFilters, secondaryMapCustomRowFilters, org, $scope.activeTab.topFilters, true).then(function (topFilterData) {
            $scope.topFilterData = fastParseTopFiltersData($scope, topFilterData);
            $scope.parentScope.$parent.hideLoader();
          })
        } else {} */
        genericFactory
          .getByProperties(
            primaryMapCustomRowFilters,
            org,
            null,
            $scope.module.paginationSize,
            $scope.module.paginationStartIndex
          )
          .then(function (primaryData) {
            if (Object.keys(secondaryMapCustomRowFilters).length > 0) {
              genericFactory
                .getByProperties(
                  secondaryMapCustomRowFilters,
                  org,
                  null,
                  $scope.module.paginationSize,
                  $scope.module.paginationStartIndex
                )
                .then(function (secondaryData) {
                  let primarySecondaryData = primaryData.concat(secondaryData);
                  primarySecondaryData = getUniqueArrObjects(primarySecondaryData, "id");
                  if ($scope.activeTab.filteredDataTab && mode == "filteredDataTab") {
                    $scope.filterDataByTopFilters();
                  } else {
                    $scope.runCustomFunctions(primarySecondaryData);
                  }
                });
            } else if ($scope.activeTab.filteredDataTab && mode == "filteredDataTab") {
              $scope.filterDataByTopFilters();
            } else {
              $scope.runCustomFunctions(primaryData);
            }
          });
      } else {
        genericFactory.getByProperty("organization", org, $scope.module.limitRowModule).then(function (data) {
          $scope.runCustomFunctions(data, mode);
        });
      }
    };

    $scope.runCustomFunctions = function (data, mode) {
      var customFunctions = $scope.activeTab.customFunctions;
      if (customFunctions != null) {
        for (let i = 0; i < customFunctions.length; i++) {
          const element = customFunctions[i];
          if (utilFunctions[element] !== null && utilFunctions[element] !== undefined) {
            utilFunctions[element]($scope, data);
          }
        }
      }
      $scope.setGridData(data, mode);
    };

    $scope.filterDataByTopFilters = function () {
      let dataFilteredByTopFilters = [];
      if ($scope.filteredDataReadyForFilter.length > 0) {
        /* if ($scope.deps && $scope.deps.length > 0) {
          for (let i = 0; i < $scope.filteredDataReadyForFilter.length; i++) {
            let dataEl = $scope.filteredDataReadyForFilter[i];
            let matchingEl = true;
            for (const filter in $scope.topFilters) {
              if ($scope.topFilters.hasOwnProperty(filter)) {
                const filterValue = $scope.topFilters[filter];
                let fromTableTopFilter = $scope.topFilterData[filter].filter(o => o.parsedValue == filterValue)[0];
                if (fromTableTopFilter != null) {
                  if (dataEl[filter] != fromTableTopFilter.rawValue) {
                    matchingEl = false;
                    break;
                  }
                }
              }
            }
            if (matchingEl) {
              dataFilteredByTopFilters.push(dataEl);
            }
          }
          $scope.setGridData(dataFilteredByTopFilters, "filteredDataTab");
        } else { */
        /* genericFactory.getByProperties($scope.topFilters, $scope.currentUser.organization).then(function (data) {
          $scope.setGridData(data, "filteredDataTab");
        }) */
        for (let i = 0; i < $scope.filteredDataReadyForFilter.length; i++) {
          let dataEl = $scope.filteredDataReadyForFilter[i];
          let matchingEl = true;
          for (const filter in $scope.topFilters) {
            if ($scope.topFilters.hasOwnProperty(filter)) {
              const filterValue = $scope.topFilters[filter];
              if (dataEl[filter] != filterValue) {
                matchingEl = false;
                break;
              }
            }
          }
          if (matchingEl) {
            if (
              $scope.module.limitRowModule != null &&
              dataFilteredByTopFilters.length > $scope.module.limitRowModule
            ) {
              $mdDialog.show(
                $mdDialog
                  .alert()
                  .clickOutsideToClose(true)
                  .title("Limite de Consulta de Registos")
                  .textContent(
                    "Por favor selecione um número de registos a consultar mais limitado (utilizando os filtros disponíveis) de forma a facilitar uma resposta rápida à procura de informação desejada."
                  )
                  .ariaLabel("Limite de Consulta de Registos")
                  .ok("Ok")
              );
              $scope.parentScope.$parent.hideLoader();
              return;
            }
            dataFilteredByTopFilters.push(dataEl);
          }
        }
        $scope.setGridData(dataFilteredByTopFilters, "filteredDataTab");
        /* } */
      } else {
        $scope.parentScope.$parent.hideLoader();
      }
    };

    $scope.setGridData = function (data, mode) {
      if (mode != "filteredModuleDataByTopFilters") {
        $scope.updateTopFiltersData(data);
      }
      if (
        (($scope.gridOptions.data && $scope.gridOptions.data.length === 0) || $scope.isChangingTab) &&
        mode != "filteredDataTab"
      ) {
        $scope.isChangingTab = false;
      }
      if ($scope.activeTab.filteredDataTab && mode != "filteredDataTab" && mode != "filteredModuleDataByTopFilters") {
        $scope.gridOptions.data = [];
        $scope.filteredDataReadyForFilter = data;
        $scope.parentScope.$parent.alertFilteredModuleTab();
      } else {
        $scope.gridOptions.data = data;
      }
      $scope.parentScope.$parent.hideLoader();
    };

    $scope.updateTopFiltersData = function (data) {
      if ($scope.activeTab.topFilters) {
        //$scope.topFilterData = parseData($scope, JSON.parse(JSON.stringify(data)));
        if ($scope.activeTab.filteredDataTab == null) {
          let topFiltersData = [];
          for (let j = 0; j < data.length; j++) {
            let el = data[j];
            topFiltersData.push(JSON.parse(JSON.stringify(el)));
          }
          $scope.topFilterData = parseTopFiltersData($scope, topFiltersData, $scope.activeTab.topFilters);
        } else {
          $scope.topFilterData = parseTopFiltersData($scope, data, $scope.activeTab.topFilters);
        }
        /* let columnHeaders = uiGridExporterService.getColumnHeaders($scope.gridApi.grid, 'visible');
        let visibleData = uiGridExporterService.getData($scope.gridApi.grid, 'visible', 'visible');
        let topFilterData = {};
        for (let i = 0; i < visibleData.length; i++) {
          let dataEl = visibleData[i];
          for (let k = 0; k < columnHeaders.length; k++) {
            let colHead = columnHeaders[k];
            if (topFilterData[colHead.name] == null) {
              topFilterData[colHead.name] = [];
            }
            if (dataEl[k] != null && dataEl[k].value != null && topFilterData[colHead.name].indexOf(dataEl[k].value) == -1) {
              topFilterData[colHead.name].push(dataEl[k].value);
            }
          }
        }
        $scope.topFilterData = topFilterData; */
      }
    };

    $scope.openPanel = function () {
      var position = $mdPanel.newPanelPosition().absolute().center();
      var config = {
        attachTo: angular.element(document.body),
        disableParentScroll: false,
        controller: "GenericModulePanelCtrl",
        controllerAs: "ctrl",
        templateUrl: "templates/genericModulePanel.html",
        hasBackdrop: true,
        panelClass: "a-panel",
        position: position,
        trapFocus: true,
        zIndex: 1,
        focusOnOpen: true,
        animation: undefined,
        onDomRemoved: function () {
          /* if ($scope.activeTab.onlyOneConcurrentEditorControl && genericFactory.getMode() != "view") {
            // Remote event listener that prevents refreshes
            window.removeEventListener("beforeunload", beforeUnloadHandler);
          } */

          // Clean row concorrent edit control
          if (
            $scope.activeTab.onlyOneConcurrentEditorControl &&
            genericFactory.getMode() != null &&
            genericFactory.getMode() != "create" &&
            genericFactory.getMode() != "view" &&
            $scope.exitingModule
          ) {
            let originalSelected = genericFactory.getOriginalSelected();
            $scope.genericFactory.get(genericFactory.getSelected().id).then(function (selected) {
              originalSelected.rowAlreadyOpen = null;
              originalSelected.rowAlreadyOpenAt = null;
              genericFactory.modify(originalSelected.id, originalSelected).then((row) => {});
            });
          }

          $scope.selectedRow = null;
          if ($scope.panelRef && Array.isArray($scope.panelRef) && $scope.panelRef.length > 0) {
            $scope.panelRef.destroy();
          } else if ($scope.panelRef && !Array.isArray($scope.panelRef)) {
            $scope.panelRef.destroy();
          }
          $scope.gridApi.selection.clearSelectedRows();
          /* if (!$scope.exitingModule) {
            $scope.getModuleData();
          } */
        },
        locals: {
          mode: mode,
          genericScope: $scope,
          activeTab: $scope.activeTab,
          activeTabName: $scope.activeTabName,
          activeModule: $scope.module,
          deps: $scope.deps,
          depsData: $scope.depsData,
          fieldFilters: $scope.fieldFilters,
          data: $scope.gridOptions.data,
          dynamicFields: $scope.dynamicFields,
          currentUser: $scope.currentUser,
        },
        onCloseSuccess: function () {
          $scope.$parent.hideLoader();
        },
        onOpenComplete: function () {
          $scope.$parent.hideLoader();
        },
      };

      $mdPanel.open(config).then(function (result) {
        $scope.panelRef = result;
      });
    };

    $scope.openUploadFilePanel = function (importerArgs) {
      var position = $mdPanel.newPanelPosition().absolute().center();

      var config = {
        attachTo: angular.element(document.body),
        disableParentScroll: false,
        controller: "UploadFilePanelCtrl",
        controllerAs: "ctrl",
        templateUrl: "templates/uploadFilePanel.html",
        hasBackdrop: true,
        panelClass: "a-panel",
        position: position,
        trapFocus: true,
        zIndex: 1,
        animation: undefined,
        onDomRemoved: function () {
          if ($scope.panelRef && Array.isArray($scope.panelRef) && $scope.panelRef.length > 0) {
            $scope.panelRef.destroy();
          } else if ($scope.panelRef && !Array.isArray($scope.panelRef)) {
            $scope.panelRef.destroy();
          }
        },
        locals: {
          genericScope: $scope,
          data: $scope.gridOptions.data,
          importerArgs: importerArgs,
        },
      };

      $mdPanel.open(config).then(function (result) {
        $scope.panelRef = result;
      });
    };

    $scope.closePanel = function (mode) {
      // Clean row concorrent edit control
      if ($scope.activeTab.onlyOneConcurrentEditorControl && mode != null && mode != "create" && mode != "view") {
        $scope.genericFactory.get(genericFactory.getSelected().id).then(function (selected) {
          selected.rowAlreadyOpen = null;
          selected.rowAlreadyOpenAt = null;
          genericFactory.modify(selected.id, selected).then((row) => {});
        });
      }

      if ($scope.panelRef != null) {
        if (Array.isArray($scope.panelRef)) {
          $scope.panelRef.pop().close();
        } else {
          $scope.panelRef.close();
        }
      }
      if (!$scope.exitingModule) {
        if ($scope.customTopFiltersFlag) {
          $scope.getModuleData("filteredModuleDataByTopFilters");
        } else {
          $scope.getModuleData();
        }
      }
    };

    $scope.$on("$destroy", function () {
      $scope.customCalculatedFieldsIntervals.forEach((intervalId) => {
        clearInterval(intervalId);
      });
      $scope.exitingModule = true;
      $scope.closePanel();
    });

    $scope.clearForm = function () {
      $scope.gridApi.selection.clearSelectedRows();
      $scope.selectedRow = null;
      genericFactory.setSelected(null);
    };

    $scope.uploadFile = function (importerArgs) {
      $scope.openUploadFilePanel(importerArgs);
    };

    $scope.create = function (event) {
      $scope.$parent.showLoader();
      $scope.clearForm();
      mode = "create";
      $scope.openPanel();
    };

    $scope.modify = function (event) {
      $scope.$parent.showLoader();
      mode = "modify";
      $scope.openPanel();
    };

    $scope.update = function (event) {
      $scope.$parent.showLoader();
      mode = "update";
      $scope.openPanel();
    };

    $scope.view = function (event) {
      $scope.$parent.showLoader();
      mode = "view";
      $scope.openPanel();
    };

    $scope.delete = function (event) {
      // Appending dialog to document.body to cover sidenav in docs app
      var confirm = $mdDialog
        .confirm()
        .title($scope.translateText("deleteRecordDialog"))
        // .textContent('All of the banks have agreed to forgive you your debts.')
        .ariaLabel("Confirmar eliminação")
        .ok($scope.translateText("yes"))
        .cancel($scope.translateText("no"));

      $mdDialog.show(confirm).then(
        function () {
          $scope.$parent.showLoader();
          genericFactory.delete($scope.selectedRow.id).then(function () {
            $scope.deleteUploadedFiles($scope.selectedRow.id);
            $scope.$parent.hideLoader();
            $scope.$parent.successfulDelete();
            $scope.getModuleData();
          });
        },
        function () {
          return;
        }
      );
    };

    $scope.setDefaultGridMenuCustomItems = function () {
      if ($scope.activeTab.hideGenericExportPDFOption) {
        $scope.gridOptions.gridMenuCustomItems = [
          {
            title: $scope.translateText("gridMenuExportAllExcel"),
            action: function ($event) {
              exportUiGridService.exportToExcel(
                "Registos",
                $scope.gridApi,
                "all",
                "all",
                $scope.module.name + " - " + $scope.activeTabName
              );
            },
            order: 110,
          },
          {
            title: $scope.translateText("gridMenuExportVisibleExcel"),
            action: function ($event) {
              exportUiGridService.exportToExcel(
                "Registos",
                $scope.gridApi,
                "visible",
                "visible",
                $scope.module.name + " - " + $scope.activeTabName
              );
            },
            order: 111,
          },
        ];
      } else {
        $scope.gridOptions.gridMenuCustomItems = [
          {
            title: $scope.translateText("gridMenuExportAllExcel"),
            action: function ($event) {
              exportUiGridService.exportToExcel(
                "Registos",
                $scope.gridApi,
                "all",
                "all",
                $scope.module.name + " - " + $scope.activeTabName
              );
            },
            order: 110,
          },
          {
            title: $scope.translateText("gridMenuExportVisibleExcel"),
            action: function ($event) {
              exportUiGridService.exportToExcel(
                "Registos",
                $scope.gridApi,
                "visible",
                "visible",
                $scope.module.name + " - " + $scope.activeTabName
              );
            },
            order: 111,
          },
          {
            title: "Exportar dados como PDF",
            action: function ($event) {
              let genericExportConfig = {
                reportTemplate: "generic",
                targetData: "filtered",
                dataTransformFunction: "genericReportExport",
                reportFileName: $scope.module.name + "_" + $scope.activeTabName + "_",
                footerTemplate: "portraitDefault",
                marginTop: "30px",
                marginBottom: "125px",
              };
              if ($scope.activeTab.exportTemplate) {
                genericExportConfig.reportTemplate = $scope.activeTab.exportTemplate;
              }
              if ($scope.activeTab.exportDataTransformFunction) {
                genericExportConfig.dataTransformFunction = $scope.activeTab.exportDataTransformFunction;
              }
              exportReport($scope, $mdPanel, genericExportConfig, true);
            },
            order: 112,
          },
        ];
      }
    };

    $scope.setGridMenuCustomItems = function (customItems) {
      for (let j = 0; j < customItems.length; j++) {
        const customItem = customItems[j];
        if (customItem.type == "exportPDF") {
          let customItemDetails = {};
          customItemDetails.title = customItem.title;
          customItemDetails.action = function ($event) {
            let genericExportConfig = {
              reportTemplate: "generic",
              targetData: "filtered",
              dataTransformFunction: "genericReportExport",
              reportFileName: "Relatório_",
              footerTemplate: "portraitDefault",
              marginTop: "30px",
              marginBottom: "150px",
            };
            if (customItem.reportFileName) {
              genericExportConfig.reportFileName = customItem.reportFileName;
            } else {
              if (customItem.exportTitle) {
                genericExportConfig.reportFileName = $scope.module.name + "_" + customItem.exportTitle + "_";
              } else {
                genericExportConfig.reportFileName = $scope.module.name + "_" + $scope.activeTabName + "_";
              }
            }
            if (customItem.exportTitle) {
              genericExportConfig.exportTitle = customItem.exportTitle;
            }
            if (customItem.exportSubTitle) {
              genericExportConfig.exportSubTitle = customItem.exportSubTitle;
            }
            if (customItem.exportTemplate) {
              genericExportConfig.reportTemplate = customItem.exportTemplate;
            }
            if ($scope.parentScope.$parent.clientHasOrganizationLogo) {
              if (customItem.exportOrientation == "landscape") {
                genericExportConfig.headerTemplate = "landscapeHeaderOrgLogo";
                genericExportConfig.footerTemplate = "landscapeDefault";
                genericExportConfig.marginTop = "80px";
              } else {
                genericExportConfig.headerTemplate = "portraitHeaderOrgLogo";
                genericExportConfig.marginTop = "120px";
              }
            }
            if (customItem.headerTemplate) {
              genericExportConfig.headerTemplate = customItem.headerTemplate;
              if (customItem.headerTemplate == "emptyHeader" || customItem.headerTemplate == "landscapeEmptyHeader") {
                genericExportConfig.marginTop = "10px";
              }
            }
            if (customItem.footerTemplate) {
              genericExportConfig.footerTemplate = customItem.footerTemplate;
              if (customItem.footerTemplate == "emptyFooter" || customItem.footerTemplate == "landscapeEmptyFooter") {
                genericExportConfig.marginBottom = "0px";
              }
            }
            if (customItem.marginTop) {
              genericExportConfig.marginTop = customItem.marginTop;
            }
            if (customItem.marginBottom) {
              genericExportConfig.marginBottom = customItem.marginBottom;
            }
            if (customItem.exportDataTransformFunction) {
              genericExportConfig.dataTransformFunction = customItem.exportDataTransformFunction;
            }
            if (customItem.totalRowFields) {
              genericExportConfig.totalRowFields = customItem.totalRowFields;
            }
            if (customItem.orgNameFlag) {
              genericExportConfig.orgNameFlag = customItem.orgNameFlag;
            }
            if (customItem.promiseDataTransfFunction) {
              genericExportConfig.promiseDataTransfFunction = customItem.promiseDataTransfFunction;
            }
            if (customItem.targetData) {
              genericExportConfig.targetData = customItem.targetData;
            }
            if (customItem.customFlags) {
              genericExportConfig.customFlags = customItem.customFlags;
            }
            if (customItem.reportNumber) {
              genericExportConfig.reportNumber = customItem.reportNumber;
            }
            if (customItem.hideOrgLogo) {
              genericExportConfig.hideOrgLogo = customItem.hideOrgLogo;
            }
            if (customItem.customReport) {
              exportReport($scope, $mdPanel, genericExportConfig);
            } else {
              exportReport($scope, $mdPanel, genericExportConfig, true);
            }
          };
          $scope.gridOptions.gridMenuCustomItems.push(customItemDetails);
        }
      }
    };

    $scope.setGridOptionsColumns = function (columns) {
      $scope.gridOptions.columnDefs = columns;
    };

    $scope.keys = function (object) {
      if (object) {
        return Object.keys(object).length;
      }
      return;
    };

    $scope.msToDate = function (num) {
      if (num != "null" && num != "undefined" && num != null) {
        var date = new Date(num).toLocaleDateString("pt-PT");
        if (date === "Invalid Date") {
          return "";
        } else {
          return date;
        }
      } else {
        return "";
      }
    };

    $scope.checkRequiredTopFilters = function () {
      if ($scope.topFilters && $scope.activeTab.requiredTopFilters) {
        for (let j = 0; j < $scope.activeTab.requiredTopFilters.length; j++) {
          const requiredTopFilterField = $scope.activeTab.requiredTopFilters[j];
          if ($scope.topFilters[requiredTopFilterField] == null || $scope.topFilters[requiredTopFilterField] == "") {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    };

    $scope.parseValue = function (id, field, auxFromTable, auxFieldName) {
      if (id != "null" && id != "undefined" && id != null) {
        var total = "";
        var idToStrings = [];
        var depData = {};

        let originalID = JSON.parse(JSON.stringify(id));

        if (!Array.isArray(id) && id.length > 24) {
          total = id.substring(24);
          id = id.substring(0, 24);
        }

        /* else {
          if (id.length != 24 || typeof id == "number") {
            return id;
          }
        } */
        if (
          !Array.isArray(id) &&
          (typeof id == "number" ||
            id.length < 24 ||
            (typeof id == "string" &&
              (id.split(" ").length - 1 > 0 ||
                id.split("/").length - 1 > 0 ||
                id.split("@").length - 1 > 0 ||
                id.split("º").length - 1 > 0)) ||
            (typeof originalID == "string" && originalID.split(",").length - 1 > 0))
        ) {
          return originalID;
        }

        if (auxFromTable == null) {
          var i = $scope.deps.indexOf($scope.getFromTable(field));
        } else {
          var i = $scope.deps.indexOf(auxFromTable);
        }

        if (i != -1) {
          if (typeof $scope.depsData !== "undefined" && $scope.depsData.length > 0) {
            depData = $scope.depsData[i];
            //var name = depData.filter(el => el.id === id);
            for (let k = 0; k < depData.length; k++) {
              let el = depData[k];
              if (el.id === id) {
                var name = new Array(el);
                break;
              }
            }
          }
        }

        if (Array.isArray(id)) {
          let numIDsParsed = 0;
          for (var j = 0; j < depData.length; j++) {
            for (var i = 0; i < id.length; i++) {
              if (depData[j].id === id[i]) {
                numIDsParsed += 1;
                if (auxFieldName != null) {
                  idToStrings.push(depData[j][auxFieldName]);
                } else {
                  idToStrings.push(depData[j][field]);
                }
              }
            }
            if (numIDsParsed == id.length) {
              break;
            }
          }
          return idToStrings.toString() + total;
        }

        if (typeof name !== "undefined" && name.length > 0) {
          if (auxFieldName != null) {
            return name[0][auxFieldName] + total;
          } else {
            return name[0][field] + total;
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    };

    $scope.getGridData = function () {
      return $scope.gridOptions.data;
    };

    $scope.getFromTable = function (field) {
      if (Object.keys($scope.activeTab.fields).indexOf(field) != -1) {
        return $scope.activeTab.fields[field].fromTable;
      } else {
        for (let i = 0; i < $scope.dynamicFields.length; i++) {
          let dynaField = $scope.dynamicFields[i];
          if (Object.keys($scope.activeTab.fields[dynaField].dynamicFields).indexOf(field) != -1) {
            return $scope.activeTab.fields[dynaField].dynamicFields[field].fromTable;
          }
        }
      }
    };

    $scope.parseViewValues = function (value, field) {
      if (
        value != null &&
        value != undefined &&
        !isNaN(parseInt(value)) &&
        String(value).match(/€/g) == null &&
        $scope.activeTab.fields[field] != undefined &&
        $scope.activeTab.fields[field].type == "currency.euro"
      ) {
        value = value.toString() + " €";
      }
      if (String(value).match(/total: /g)) {
        if (String(value).match(/undefined/g)) {
          return "0";
        }
        return value.substring(7);
      } else {
        return value;
      }
    };

    $scope.dynamicFieldParse = function (dynaField, field) {
      if (dynaField != undefined && dynaField != null) {
        if (Array.isArray(dynaField) && dynaField.length > 0) {
          var newDynaField = JSON.parse(JSON.stringify(dynaField));
          for (let i = 0; i < newDynaField.length; i++) {
            let element = newDynaField[i];
            dynaFieldFilterKeyValue(element, field);
            element[field] = $scope.parseValue(element[field], field);
            if (element["vn"].length == 0) {
              element["vn"] = "Não";
            } else {
              element["vn"] = "Sim";
            }
          }
        } else {
          var newDynaField = JSON.parse(JSON.stringify(dynaField));
          dynaFieldFilterKeyValue(newDynaField, field);
          if (newDynaField["vn"].length == 0) {
            newDynaField["vn"] = "Não";
          } else {
            newDynaField["vn"] = "Sim";
          }
          return $scope.parseValue(newDynaField[field], field);
        }
        let newDynaFieldString = JSON.stringify(newDynaField);
        newDynaFieldString = newDynaFieldString
          .replace(/"/gi, "")
          .replace(new RegExp(field, "gi"), "chave")
          .replace(/value/gi, "%")
          .replace(/\[/gi, "")
          .replace(/\]/gi, "")
          .replace(/\{/gi, "[")
          .replace(/\}/gi, "]")
          .replace(/,/gi, ", ")
          .replace(/:/gi, ": ");
        return newDynaFieldString;
      }
    };

    $scope.getFromTableData = function (fromTable, getAllFromTableFlag) {
      var i = $scope.deps.indexOf(fromTable);
      if (i != -1) {
        if (getAllFromTableFlag != null && getAllFromTableFlag == true) {
          return $scope.depsData[i];
        } else {
          return $scope.depsData[i].filter((o) => o.organization == $scope.currentUser.organization);
        }
      }
    };

    $scope.fastItems = [];
    $scope.filteredByFieldDependencies = {};

    $scope.getFastFromTableData = function (fastFlag, fromTable, fastFilterField, customLabelElements, field) {
      /* if ($scope.fastItems.length == 0 && $scope.selected[fastFilterField] != null) {
        var i = $scope.deps.indexOf(fromTable);
        if (i != -1) {
          $scope.fastItems = $scope.depsData[i].filter(o => o.organization == $scope.currentUser.organization && (o[fastFilterField] == $scope.selected[fastFilterField] || o.id == $scope.selected[fastFilterField]) );
          return $scope.fastItems;
        }
      } else {
        return $scope.fastItems;
      } */

      if (fastFlag) {
        if (customLabelElements != null) {
          if (fastFilterField == null) {
            return customLabelElements;
          } else if (fastFilterField != null && Array.isArray(fastFilterField) && fastFilterField.length > 0) {
            let filteredCustomLabelElements = [];

            if (
              $scope.filteredByFieldDependencies[field] &&
              $scope.filteredByFieldDependencies[field].filteredData &&
              !$scope.checkIfFastFilterFieldsChanged(field, fastFilterField)
            ) {
              return $scope.filteredByFieldDependencies[field].filteredData;
            }

            for (let f = 0; f < customLabelElements.length; f++) {
              let element = customLabelElements[f];
              let filteredByErrorFlag = false;
              for (let i = 0; i < fastFilterField.length; i++) {
                if ($scope.filteredByFieldDependencies[field] == null) {
                  $scope.filteredByFieldDependencies[field] = {};
                }
                if ($scope.filteredByFieldDependencies[field][fastFilterField[i]] == null) {
                  $scope.filteredByFieldDependencies[field][fastFilterField[i]] = {};
                }

                $scope.filteredByFieldDependencies[field][fastFilterField[i]] = $scope.topFilters[fastFilterField[i]];

                if ($scope.topFilters[fastFilterField[i]] != null) {
                  if ($scope.topFilters[fastFilterField[i]] == element.id) {
                    continue;
                  }
                  if ($scope.topFilters[fastFilterField[i]] == element[fastFilterField[i]]) {
                    continue;
                  }

                  if (Array.isArray(element[fastFilterField[i]])) {
                    if (element[fastFilterField[i]].indexOf($scope.topFilters[fastFilterField[i]]) != -1) {
                      continue;
                    }
                  }
                  if (Array.isArray($scope.topFilters[fastFilterField[i]])) {
                    if (
                      $scope.topFilters[fastFilterField[i]].indexOf(element.id) != -1 ||
                      $scope.topFilters[fastFilterField[i]].indexOf(element[fastFilterField[i]]) != -1
                    ) {
                      continue;
                    }
                  }
                  if (
                    Array.isArray($scope.topFilters[fastFilterField[i]]) &&
                    Array.isArray(element[fastFilterField[i]])
                  ) {
                    if (
                      $scope.topFilters[fastFilterField[i]].some((r) => element[fastFilterField[i]].indexOf(r) >= 0)
                    ) {
                      continue;
                    }
                  }
                  let filteredData = $scope.getFromTableDataElBydId(
                    $scope.activeTab.fields[field].fromTable,
                    element.id
                  );
                  if (filteredData != null) {
                    if (!Array.isArray(filteredData[fastFilterField[i]])) {
                      if ($scope.topFilters[fastFilterField[i]] == filteredData[fastFilterField[i]]) {
                        continue;
                      }
                    } else {
                      if (filteredData[fastFilterField[i]].indexOf($scope.topFilters[fastFilterField[i]]) != -1) {
                        continue;
                      }
                    }
                  }
                  filteredByErrorFlag = true;
                  break;
                }
              }
              if (!filteredByErrorFlag) {
                filteredCustomLabelElements.push(element);
              }
            }

            $scope.filteredByFieldDependencies[field].filteredData = filteredCustomLabelElements;
            return filteredCustomLabelElements;
          }
        }
        if ($scope.fastItems.length == 0) {
          let i = $scope.deps.indexOf(fromTable);
          if (i != -1) {
            $scope.fastItems = $scope.depsData[i].filter((o) => o.organization == $scope.currentUser.organization);
          }
        } else {
          if (fastFilterField == null) {
            return $scope.fastItems;
          } else {
            return $scope.fastItems.filter(
              (o) =>
                o[fastFilterField] == $scope.topFilters[fastFilterField] ||
                o.id == $scope.topFilters[fastFilterField] ||
                (Array.isArray($scope.topFilters[fastFilterField]) &&
                  $scope.topFilters[fastFilterField].indexOf(o[fastFilterField]) != -1)
            );
          }
        }
      }
    };

    $scope.topVerification = function (filter) {
      var matchingFieldsRaw = $scope.gridOptions.data.filter(function (el) {
        let searchText = JSON.parse(JSON.stringify($scope.topVerifications[filter]));
        searchText = searchText.toString().replace(/ /gi, ".*");
        var re = new RegExp(searchText, "gi");
        if (el[filter] != undefined) {
          return el[filter].toString().match(re);
        }
      });

      var count = matchingFieldsRaw.length;
      var years = [];

      var matchingFieldsParsed = matchingFieldsRaw.map(function (item) {
        if (item["year"] != null) {
          if (years.indexOf(item["year"].toString()) == -1) {
            years.push(item["year"].toString());
          }
        }
        //return "" + $scope.activeTab.fields[Object.keys($scope.activeTab.fields)[0]].label + ": "+  item[Object.keys(item)[1]] + ", " + $scope.activeTab.fields[Object.keys($scope.activeTab.fields)[1]].label + ": "+  item[Object.keys(item)[2]] + ", " + $scope.activeTab.fields[Object.keys($scope.activeTab.fields)[2]].label + ": "+  item[Object.keys(item)[3]] + ";";
      });

      years = years.sort();

      if (matchingFieldsParsed.length == 1) {
        $mdDialog.show(
          $mdDialog
            .alert()
            .clickOutsideToClose(true)
            .title(
              "Registos encontrados (" + $scope.activeTab.fields[filter].label + "): " + $scope.topVerifications[filter]
            )
            .textContent("Foi encontrado 1 registo no ano " + years.toString().replace(/,/g, ", "))
            .ariaLabel("Procura de registo")
            .ok("Ok")
        );
      } else if (matchingFieldsParsed.length > 1) {
        $mdDialog.show(
          $mdDialog
            .alert()
            .clickOutsideToClose(true)
            .title(
              "Registos encontrados (" + $scope.activeTab.fields[filter].label + "): " + $scope.topVerifications[filter]
            )
            .textContent("Foram encontrados " + count + " registos nos anos: " + years.toString().replace(/,/g, ", "))
            .ariaLabel("Procura de registo")
            .ok("Ok")
        );
      } else {
        $mdDialog.show(
          $mdDialog
            .alert()
            .clickOutsideToClose(true)
            .title(
              "Nenhum registo encontrado (" +
                $scope.activeTab.fields[filter].label +
                "): " +
                $scope.topVerifications[filter]
            )
            .ariaLabel("Procura de registo")
            .ok("Ok")
        );
      }
    };

    $scope.showLoad = function () {
      $scope.parentScope.$parent.showLoader();
    };

    $scope.hideLoad = function () {
      $scope.parentScope.$parent.hideLoader();
    };

    $scope.customButtonFunction = function (functionName, functionArgs) {
      if (utilFunctions[functionName] !== null && utilFunctions[functionName] !== undefined) {
        utilFunctions[functionName]($scope, functionArgs, $scope.gridOptions.data, $scope.selectedRow);
      } else {
        window[functionName]($scope, $mdPanel, functionArgs, $scope.selectedRow, $scope);
      }
    };

    $scope.customButtonShowFunction = function (showCondition) {
      if (showCondition != null) {
        let disableConditionEvalResult = eval(showCondition.replace(new RegExp("><", "g"), "$scope"));
        return disableConditionEvalResult;
      } else {
        return true;
      }
    };

    $scope.customButtonDisableFunction = function (disabledCondition) {
      if (disabledCondition != null) {
        let disableConditionEvalResult = eval(disabledCondition.replace(new RegExp("><", "g"), "$scope"));
        return disableConditionEvalResult;
      }
    };

    $scope.customParseFunction = function (parseFunction, value, field) {
      var parsedValue = eval(
        parseFunction
          .replace(new RegExp("{}", "g"), "value")
          .replace(new RegExp("}{", "g"), "field")
          .replace(new RegExp("><", "g"), "$scope")
      );
      return parsedValue;
    };

    $scope.customFilterFunction = function (functionName, searchTerm, cellValue, row, column) {
      return window[functionName]($scope, searchTerm, cellValue, row, column);
    };

    $scope.getTableControllerFlag = function (tableController) {
      if ($scope.userModulePermissions) {
        if ($scope.userModulePermissions.indexOf(tableController) != -1) {
          return true;
        } else {
          return false;
        }
      }
      if (
        $scope.activeTab.tableControllerProfileExceptions != null &&
        $scope.activeTab.tableControllerProfileExceptions[$scope.currentUser.profile] != null &&
        $scope.activeTab.tableControllerProfileExceptions[$scope.currentUser.profile].indexOf(tableController) != -1
      ) {
        return false;
      } else {
        return true;
      }
    };

    $scope.deleteUploadedFiles = function deleteUploadedFiles(rowID) {
      for (let field in $scope.activeTab.fields) {
        if ($scope.activeTab.fields.hasOwnProperty(field)) {
          let fieldDesc = $scope.activeTab.fields[field];
          if (fieldDesc.viewType == "fileUpload") {
            //Container name
            let containerName = $scope.currentUser.organization + $scope.module.collection;
            storageFactory.getContainer(containerName).then((ct) => {
              storageFactory.listFilesInContainer(containerName).then((fileList) => {
                //Delete all files related to selected id
                for (let k = 0; k < fileList.data.length; k++) {
                  let storedFile = fileList.data[k];
                  if (storedFile.name.indexOf(rowID) != -1) {
                    storageFactory.deleteFile(containerName, storedFile.name);
                  }
                }
              });
            });
          }
        }
      }
    };

    $scope.isArrayPanelOption = function (object) {
      if (object) {
        return Array.isArray(object);
      }
    };

    $scope.customTopFilterFunction = function (field) {
      return function (element) {
        if ($scope.fieldFilters != null) {
          var filteredBy = $scope.fieldFilters[field];
          if (filteredBy && filteredBy.length > 0) {
            for (let i = 0; i < filteredBy.length; i++) {
              if ($scope.topFilters[filteredBy[i]] != null) {
                if ($scope.topFilters[filteredBy[i]] == element.id) continue;
                if ($scope.topFilters[filteredBy[i]] == element[filteredBy[i]]) continue;
                let filteredData = $scope
                  .getFromTableData($scope.activeTab.fields[field].fromTable)
                  .filter((o) => o.organization == $scope.currentUser.organization && o.id == element)[0];
                if (filteredData != null) {
                  if (!Array.isArray(filteredData[filteredBy[i]])) {
                    if ($scope.topFilters[filteredBy[i]] == filteredData[filteredBy[i]]) continue;
                  } else {
                    if (filteredData[filteredBy[i]].indexOf($scope.topFilters[filteredBy[i]]) != -1) continue;
                  }
                }
                if (Array.isArray(element[filteredBy[i]])) {
                  if (element[filteredBy[i]].indexOf($scope.topFilters[filteredBy[i]]) != -1) continue;
                }
                if (Array.isArray($scope.topFilters[filteredBy[i]])) {
                  if (
                    $scope.topFilters[filteredBy[i]].indexOf(element.id) != -1 ||
                    $scope.topFilters[filteredBy[i]].indexOf(element[filteredBy[i]]) != -1
                  )
                    continue;
                }
                if (Array.isArray($scope.topFilters[filteredBy[i]]) && Array.isArray(element[filteredBy[i]])) {
                  if ($scope.topFilters[filteredBy[i]].some((r) => element[filteredBy[i]].indexOf(r) >= 0)) continue;
                }
                return false;
              }
            }
            return true;
          }
          return true;
        }
      };
    };

    $scope.disableRequiredFieldCheck = function (requiredFields) {
      if (requiredFields != null && Array.isArray(requiredFields) && requiredFields.length > 0) {
        for (let i = 0; i < requiredFields.length; i++) {
          const requiredField = requiredFields[i];
          if (
            $scope.topFilters[requiredField] == null ||
            $scope.topFilters[requiredField] == undefined ||
            $scope.topFilters[requiredField] == "" ||
            $scope.topFilters[requiredField] == []
          ) {
            return true;
          }
        }
        return false;
      }
    };

    $scope.syncGenericModuleData = function (event) {
      if ($scope.gridOptions.data) {
        $scope.$parent.showLoader();
        let dataClone = structuredClone($scope.gridOptions.data);

        let dataHeadersWithHiddenOrInvalidData = [];

        let headerInfo = [];
        let headerInfoMap = {};
        let headerFields = [];
        for (const field in $scope.activeTab.fields) {
          if (Object.hasOwnProperty.call($scope.activeTab.fields, field)) {
            let fieldDesc = $scope.activeTab.fields[field];
            if (
              fieldDesc.hidden == null &&
              fieldDesc.hiddenInTable == null &&
              fieldDesc.viewType != "dynamicField" &&
              fieldDesc.viewType != "fileUpload"
            ) {
              let type;
              if (fieldDesc.viewType == "date") {
                type = "string";
              } else {
                type = fieldDesc.type;
              }
              headerInfo.push({ field: field, label: fieldDesc.label, type: type });
              headerInfoMap[field] = { field: field, label: fieldDesc.label, type: type };
              headerFields.push(field);
            } else {
              dataHeadersWithHiddenOrInvalidData.push(field);
            }
          }
        }

        let tableName =
          $scope.module.syncModuleDataToPostgresDevTable != null ? "qa_" + $scope.module.value : $scope.module.value;

        let data = parseData($scope, dataClone);

        data.forEach((dataEl) => {
          dataHeadersWithHiddenOrInvalidData.forEach((field) => {
            delete dataEl[field];
          });
          let filledFields = [];
          for (let field in dataEl) {
            if (Object.hasOwnProperty.call(dataEl, field)) {
              let dataElValue = dataEl[field];
              if (headerFields.indexOf(field) == -1) {
                delete dataEl[field];
              } else if (
                dataElValue == null ||
                dataElValue == [] ||
                (Array.isArray(dataElValue) && dataElValue.length == 0)
              ) {
                dataEl[field] = undefined;
              }
              if (headerFields.indexOf(field) != -1 && dataElValue != null) {
                filledFields.push(field);
              }
            }
          }
          headerFields.forEach((headerField) => {
            if (
              (filledFields.indexOf(headerField) == -1 || dataEl[headerField] == null) &&
              headerInfoMap[headerField].type == "string"
            ) {
              dataEl[headerField] = "";
            }
            if (
              (dataEl[headerField] == "" || dataEl[headerField] == null) &&
              headerInfoMap[headerField].type == "number"
            ) {
              delete dataEl[headerField];
            }
          });
          delete dataEl.id;
          delete dataEl.organization;
        });

        let response;

        genericFactory
          .syncGenericModuleData({
            id: $scope.currentUser.organization,
            tableName: tableName,
            headerInfo: headerInfo,
            data: data,
          })
          .then(function (result) {
            if (result.data) {
              $mdToast.show(
                $mdToast
                  .simple()
                  .textContent("A sincronizar informação...")
                  .position("top right")
                  .hideDelay(3000)
                  .theme("success-toast")
              );
            } else {
              $mdToast.show(
                $mdToast
                  .simple()
                  .textContent("Ocorreu um erro")
                  .position("top right")
                  .hideDelay(3000)
                  .theme("success-toast")
              );
            }
            response = result;
          })
          .finally(function () {
            $scope.$parent.hideLoader();
            if (response.data) {
              $timeout(function () {
                $mdToast.show(
                  $mdToast
                    .simple()
                    .textContent("Concluído. A tabela " + tableName + " foi atualizada.")
                    .position("top right")
                    .hideDelay(3000)
                    .theme("success-toast")
                );
              }, 3100);
            }
          });
      } else {
        $mdToast.show(
          $mdToast
            .simple()
            .textContent("Não existe informação a sincronizar")
            .position("top right")
            .hideDelay(3000)
            .theme("success-toast")
        );
        $scope.$parent.hideLoader();
      }
    };
  },
]);
