import React from "react";
import "../../stylesheets/react.scss";
import { KSTKAnalyticsModuleReportList } from "./KSTKAnalyticsModuleReportList";
import { KSTKAnalyticsButton } from "./KSTKAnalyticsButton";
import KSTKAnalyticsCallToActionCard from "./KSTKAnalyticsCallToActionCard";
import KSTKAnalyticsSystemAlert from "./KSTKAnalyticsSystemAlert";

export class KSTKAnalyticsHomepage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let contentElement = document.getElementsByClassName("kstkContentWrapper")[0];
    let smartContentControllers = document.getElementsByClassName("kstkHeaderSmartContentsControllers")[0];
    let sidNavWrapperElement = document.getElementsByClassName("kstkSideNavWrapper")[0];
    let sidNavLongFooterElement = document.getElementsByClassName("kstkSideNavLongFooterWrapper")[0];
    let sidNavShortFooterElement = document.getElementsByClassName("kstkSideNavShortFooterWrapper")[0];
    let footerElement = document.getElementsByClassName("kstkFooter")[0];
    let footerSpacerElement = document.getElementsByClassName("kstkFooterSpacer")[0];

    contentElement.classList.add("kstkContentWrapperHomepage");
    smartContentControllers.classList.add("kstkHeaderSmartContentsControllersHomepage");
    sidNavWrapperElement.classList.add("kstkSideNavWrapperHomepage");
    sidNavLongFooterElement.classList.add("kstkSideNavLongFooterWrapperHomepage");
    sidNavShortFooterElement.classList.add("kstkSideNavShortFooterWrapperHomepage");
    footerElement.classList.add("kstkFooterHomepage");
    footerSpacerElement.classList.add("kstkFooterSpacerHomepage");
  }

  componentWillUnmount() {
    let contentElement = document.getElementsByClassName("kstkContentWrapper")[0];
    let smartContentControllers = document.getElementsByClassName("kstkHeaderSmartContentsControllers")[0];
    let sidNavWrapperElement = document.getElementsByClassName("kstkSideNavWrapper")[0];
    let sidNavLongFooterElement = document.getElementsByClassName("kstkSideNavLongFooterWrapper")[0];
    let sidNavShortFooterElement = document.getElementsByClassName("kstkSideNavShortFooterWrapper")[0];
    let footerElement = document.getElementsByClassName("kstkFooter")[0];
    let footerSpacerElement = document.getElementsByClassName("kstkFooterSpacer")[0];

    contentElement.classList.remove("kstkContentWrapperHomepage");
    smartContentControllers.classList.remove("kstkHeaderSmartContentsControllersHomepage");
    sidNavWrapperElement.classList.remove("kstkSideNavWrapperHomepage");
    sidNavLongFooterElement.classList.remove("kstkSideNavLongFooterWrapperHomepage");
    sidNavShortFooterElement.classList.remove("kstkSideNavShortFooterWrapperHomepage");
    footerElement.classList.remove("kstkFooterHomepage");
    footerSpacerElement.classList.remove("kstkFooterSpacerHomepage");
  }

  render() {
    return (
      <div
        className={
          this.props.smartContents && this.props.smartContents.length > 0
            ? "kstkCategoryView kstkCategoryViewWithSmartContents"
            : "kstkCategoryView"
        }
      >
        {/* <div className="kstkHomepageEmptyStateWrapper">
            <div className="kstkHomepageEmptyState">
              {this.props.user ? (
                <div className="kstkHomepageEmptyStateUsername">
                  <h1>Olá, {this.props.user.name}</h1>
                </div>
              ) : null}
              <div>Visitados recentemente & favoritos</div>
              <div className="kstkHomepageEmptyStateMessage">
                Aceda ao menu e visite os módulos e relatórios, podendo adicionar aos favoritos os que achar mais relevantes para uma consulta rápida
                aqui na página inicial.
              </div>
              <div className="kstkHomepageEmptyStateImg"></div>
              <KSTKAnalyticsButton action="kstkSmallPrimaryButton" text="Explorar" clickFunction={this.props.exploreClick} />
            </div>
          </div> */}
        <React.Fragment>
          {this.props.user ? (
            <div className="kstkCategoryViewHeader">
              <h1> {this.props.translateText("homepageHeader") + ", " + this.props.user.name}</h1>
            </div>
          ) : null}
          <div style={{ width: "100%" }}>
            <div className="kstkTitle">
              <div className="kstkTitleIcon">
                <ion-icon name="time-outline"></ion-icon>
              </div>
              <h3>{this.props.translateText("homepageRecentlyVisited")}</h3>
            </div>
            {this.props.userPreferences &&
            this.props.userPreferences.recentlyViewed &&
            this.props.userPreferences.recentlyViewed.length > 0 ? (
              <KSTKAnalyticsModuleReportList
                list={this.props.userPreferences.recentlyViewed}
                catModuleReportClick={this.props.catModuleReportClick}
              />
            ) : (
              <React.Fragment>
                <KSTKAnalyticsCallToActionCard
                  image="recently_viewed_tutorial_cta"
                  topic={this.props.translateText("homepageRecentlyVisitedList")}
                  description={this.props.translateText("homepageRecentlyVisitedListDesc")}
                  callToActionButton={this.props.getLanguage() == "pt-PT" ? true : false}
                  callToActionText={this.props.translateText("seeMore")}
                  callToActionVideoLink="https://www.youtube.com/embed/yavsE5DhlmY"
                />
              </React.Fragment>
            )}
          </div>
          <div style={{ width: "100%" }}>
            <div className="kstkTitle">
              <div className="kstkTitleIcon">
                <ion-icon name="heart-outline"></ion-icon>
              </div>
              <h3>{this.props.translateText("homepageFavorites")}</h3>
            </div>
            {this.props.userPreferences &&
            this.props.userPreferences.favorites &&
            this.props.userPreferences.favorites.length > 0 ? (
              <KSTKAnalyticsModuleReportList
                list={this.props.userPreferences.favorites}
                catModuleReportClick={this.props.catModuleReportClick}
                getFavoriteStatus={this.props.getFavoriteStatus}
                addRemoveFavorites={this.props.addRemoveFavorites}
              />
            ) : (
              <React.Fragment>
                <KSTKAnalyticsCallToActionCard
                  image="favorites_tutorial_cta"
                  topic={this.props.translateText("homepageFavoritesList")}
                  description={this.props.translateText("homepageFavoritesListDesc")}
                  callToActionButton={this.props.getLanguage() == "pt-PT" ? true : false}
                  callToActionText={this.props.translateText("seeMore")}
                  callToActionVideoLink="https://www.youtube.com/embed/1AZQvB8pS5w"
                />
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
        {/* {this.props.userPreferences &&
        (this.props.userPreferences.viewedSystemAlerts == null ||
          (this.props.userPreferences.viewedSystemAlerts != null &&
            Array.isArray(this.props.userPreferences.viewedSystemAlerts) &&
            this.props.userPreferences.viewedSystemAlerts.indexOf("newInterface2021") == -1)) ? (
          <KSTKAnalyticsSystemAlert
            alertName="newInterface2021"
            image="new_interface_system_alert"
            heading="Novo Predictive Analytics"
            description="Está disponível o novo interface de utilizador do sistema com o objetivo de simplificar e otimizar a experiência de utilização do mesmo. Trabalhamos com empenho para que se possa concentrar no essencial."
            listItems={["Visitados Recentemente", "Favoritos", "Menu reorganizado", "Novo método de navegação"]}
            closeSystemAlert={this.props.closeSystemAlert}
          />
        ) : null} */}
        {/* {this.props.userPreferences &&
        (this.props.userPreferences.viewedSystemAlerts == null ||
          (this.props.userPreferences.viewedSystemAlerts != null &&
            Array.isArray(this.props.userPreferences.viewedSystemAlerts) &&
            this.props.userPreferences.viewedSystemAlerts.indexOf("2023XmasAlert") == -1)) ? (
          <KSTKAnalyticsSystemAlert
            alertName="2023XmasAlert"
            image="xmas_alert"
            heading="Festas Felizes"
            description="Nesta altura refletimos sempre sobre as nossas relações e parcerias, e o valor do trabalho colaborativo que desenvolvemos.
            Que o espírito de Natal inspire energia para um novo ano de 2024 cheio de sucesso.
            Obrigado pela confiança e colaboração, Festas Felizes !"
            closeSystemAlert={this.props.closeSystemAlert}
          />
        ) : null} */}
        {/* {this.props.userPreferences &&
        (this.props.userPreferences.viewedSystemAlerts == null ||
          (this.props.userPreferences.viewedSystemAlerts != null &&
            Array.isArray(this.props.userPreferences.viewedSystemAlerts) &&
            this.props.userPreferences.viewedSystemAlerts.indexOf("2024XmasAlert") == -1)) ? (
          <KSTKAnalyticsSystemAlert
            alertName="2024XmasAlert"
            image="xmas_alert"
            heading="Boas Festas"
            description="A equipa da KSTK - Knowledge Support Technology, deseja a todos boas festas e um novo ano de 2025 cheio de sucesso! A melhor forma de começar 2025, é com a decisão certa. Conte connosco para construir um futuro mais preditivo. 🚀"
            closeSystemAlert={this.props.closeSystemAlert}
          />
        ) : null} */}
      </div>
    );
  }
}
